





















































import { Vue, Component, Model, Emit } from "vue-property-decorator";
import Editor from "@/components/editor/index.vue";
import { api_customer, post_qa } from "@/api";
import { editor_length } from "@/util/string";
import { OtherModule } from "@/store/other";
type type = "list" | "info" | "question";

@Component({
  components: { Editor },
})
export default class extends Vue {
  @Model("update:type", { required: true, type: String })
  type!: type;
  @Emit("update:type")
  ret_list(): type {
    return "list";
  }

  @Emit("clear")
  clear() {
    return null;
  }

  info: post_qa = {
    question: "",
    title: "",
  };

  async submit() {
    if (this.info.title === "") {
      return this.$message.error("제목을 작성해 주세요.");
    }
    if (this.info.question === "") {
      return this.$message.error("내용을 작성해 주세요.");
    }
    if (editor_length(this.info.question) > 1000) {
      return this.$message.error(
        "글자 수 제한이 초과되었습니다. 1000자 이내로 작성해 주세요."
      );
    }
    this._loading = true;
    await api_customer.post_qa(this.info).finally(() => {
      this._loading = false;
    });
    this.$message.success("문의사항이 제출 되었습니다.");
    this.clear();
    this.ret_list();
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
