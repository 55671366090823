




import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component
export default class extends Vue {
  @Prop({ required: true })
  val!: string | null;

  get value(): string {
    if (typeof this.val === "string") {
      return this.val;
    }
    return "";
  }

  @Watch("value", { immediate: true })
  watch_val(): void {
    this.$nextTick(() => {
      const dom = this.$refs["inner"] as Element;
      [...dom.getElementsByTagName("a")].forEach((x) => {
        x.target = "_self";
      });
    });
  }
}
